@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /*font-family: 'Noto Sans';*/
}

html,
body {
    height: 100%;
}

.phones {
    width: 296px;
    height: 40px;
    border: 1px solid black;
    border-radius: 5px;
    margin-left:5px;
}

ul > li {
    list-style-type: disc;
}

ol > li {
     list-style-type: decimal;
}

.content {
    min-height: calc(100vh - 80px);
}

.links {
    color: #00A4DC;
    flex-wrap: wrap;
}

.tabs {
    display: flex;
    justify-content: space-between;
}

.tabs button {
    border-bottom: 2px solid #00A4DC;
    color: #00A4DC;
    cursor: pointer;
    height: 50px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5), #F2F6F9);
}

.tabs button:disabled {
    background: #00A4DC;
    color: white;
}

.mini-inner-swiper{
    max-width: 95%;
    height: 100px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.mini-inner-swiper .swiper{
    width: 100%;
    box-sizing: border-box;
    caret-color: transparent;
    display: flex;
    align-items: center;
}

.mini-inner-swiper .swiper-slide-thumb-active{
    border: 3px solid #00A4DC;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.mini-inner-swiper .swiper-slide{
    height: 80px!important;
    width: 90px!important;
    display: flex;
    align-items: center;
}
.mini-inner-swiper img{
    width: 100%;
    height: 100%;
    display: block;
    display: flex;
    align-items: center;
}
/*p > img {*/
/*    width: 200px!important;*/
/*    height: 440px!important;*/
/*}*/

@media screen and (max-width: 1240px) {
    /* Styles applied when the screen width is 768 pixels or less */

    p > img {
        /*width: 1000px!important;*/
        height: 580px!important;
    }
}

@media screen and (max-width: 760px) {
    /* Styles applied when the screen width is 768 pixels or less */
    p > iframe {
        max-width: 520px!important;
        max-height: 300px!important;
    }
    p > img {
        /*width: 200px!important;*/
        height: 440px!important;
    }
}

@media screen and (max-width: 580px) {
    p > iframe {
        max-width: 400px!important;
        max-height: 240px!important;
    }
    p > img {
        /*width: 400px!important;*/
        height: 340px!important;
    }
}

@media screen and (max-width: 460px) {
    /* Styles applied when the screen width is 768 pixels or less */
    p > iframe {
        max-width: 300px!important;
        max-height: 180px!important;
    }
    p > img {
        /*width: 300px!important;*/
        height: 200px!important;
    }
}

p > iframe {
    margin: 20px auto!important;
    max-width: 660px;
    max-height: 400px;
}

.space {
    margin: 13px 0;
}

.shadows {
    margin: 10px 20px;
    box-shadow:rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

html, body {
    margin-bottom: 0;
    padding: 0;
    height: 100%;
    position: relative;
}

.swiper_pagination {
    display: flex!important;
    width: 70px;
    height: 60px;
}

.swiper-button-next,
.swiper-button-prev {
    display: none;
    color: white !important;
}

.swiper-slide-thumb-active{
    border: 3px solid #00A4DC;
    width: 70px;
    height: 60px;
    display: flex;
    align-items: center;
}

.event-img{
    width: 100%;
    height: auto;
    background-position: bottom;
    background-size:cover;
    background-repeat: no-repeat;
}

.detail-next {
    background-image: url(assets/image/main/rigth.png);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    width: 10px !important;
    height: 16px !important;
    cursor: pointer;
    z-index: 99!important;
    margin-left: 16px;
}

.detail-prev {
    background-image: url(assets/image/main/left.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    width: 10px !important;
    height: 16px !important;
    cursor: pointer;
    z-index: 99!important;
}

.info-next {
    background-image: url(assets/image/main/arrow-left.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    width: 6px !important;
    height: 12px !important;
    cursor: pointer;
    z-index: 99!important;
    margin-left: 16px;
}

.info-prev {
    background-image: url(assets/image/main/left.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    width: 6px !important;
    height: 12px !important;
    cursor: pointer;
    z-index: 99!important;
}

.swiper-next {
    background-image: url(assets/image/main/rigth.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    width: 10px !important;
    height: 20px !important;
    cursor: pointer;
    z-index: 99!important;
}

.swiper-prev {
    background-image: url(assets/image/main/left.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    width: 10px !important;
    height: 20px !important;
    cursor: pointer;
    z-index: 99!important;
}

.banner-next {
    background-image: url(assets/image/main/arrow-rigth.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    width: 10px !important;
    height: 20px !important;
    cursor: pointer;
    z-index: 99!important;
    font-size: 32px;
}

.banner-prev {
    background-image: url(assets/image/main/arrow-left.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    width: 10px !important;
    height: 20px !important;
    cursor: pointer;
    z-index: 99!important;
    font-size: 32px;
}

.button-next {
    background-image: url(assets/image/main/rigth.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    width: 10px !important;
    height: 20px !important;
    cursor: pointer;
}

.button-prev {
    background-image: url(assets/image/main/left.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    width: 10px !important;
    height: 20px !important;
    cursor: pointer;
}

.next {
    background-image: url(assets/image/main/rigth.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 10px;
    height: 20px;
    cursor: pointer;
}

.prev {
    background-image: url(assets/image/main/left.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 10px;
    height: 20px;
    cursor: pointer;
}

.activity {
    border-bottom: 2px solid #3070B6;
}

.paginate {
    display: flex;
    width: 100%;
    justify-content: center;

    z-index: 100;
}

@media screen and (max-width: 1050px) {
    .pagination {
        margin-top: 0!important;
    }
}

.pagination {
    margin-top: 32px;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #F2F6F9;
    color: black;
}

.pagination > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 38px;
    margin: 4px;
    border-radius: 4px;
    background-color: #F2F6F9;
}

.pagination > li > a {
    margin: 4px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    border-color: blue;
    outline: none ;
}

.pagination > li > a, .pagination > li > span{
    color: blue;
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
    color: gray;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    color: gray;
}

.paginGallery {
    display: flex;
    list-style: none;
    outline: none;
}

.paginGallery > .active > a {
    background-color: #F2F6F9;
    color: black;
}

.paginGallery > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 38px;
    margin: 4px;
    border-radius: 4px;
    background-color: #F2F6F9;
}

.paginGallery > li > a {
    margin: 4px;
    outline: none;
    cursor: pointer;
}

.paginGallery > .active > a, .paginGallery > .active > span, .paginGallery > .active > a:hover, .paginGallery > .active > span:hover, .paginGallery > .active > a:focus, .paginGallery > .active > span:focus{
    border-color: blue;
    outline: none ;
}

.paginGallery > li > a, .paginGallery > li > span{
    color: blue;
}

.paginGallery > li:first-child > a, .paginGallery > li:first-child > span, .paginGallery > li:last-child > a, .paginGallery > li:last-child > span{
    border-radius: unset
}

.paginGallery > li:first-child > a,
.paginGallery > li:first-child > span {
    color: gray;
}
.paginGallery> li:last-child > a,
.paginGallery > li:last-child > span {
    color: gray;
}

.hover {
    --s: 2px;   /* the thickness of the line */
    --c: #ffffff; /* the color */
    --_p: 100%;
    color: #0000;
    padding-bottom: var(--s);
    background: white;
    background:
            linear-gradient(90deg,var(--c) 50%,#000 0) calc(100% - var(--_p,0%))/200% 100%,
            linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
    -webkit-background-clip: text,padding-box;

}

.hovers {
    --s: 2px;   /* the thickness of the line */
    --c: #ffffff; /* the color */
    color: #0000;
    padding-bottom: var(--s);
    background: white;
    background:
            linear-gradient(90deg,var(--c) 50%,#000 0) calc(100% - var(--_p,0%))/200% 100%,
            linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
    -webkit-background-clip: text,padding-box;

}

.hovers:hover {
    --_p: 100%;
}

.hover:hover {
    --_p: 100%;
}

.menu-hover {
    --s: 2px;   /* the thickness of the line */
    --c: #ffffff; /* the color */

    color: #0000;
    padding-bottom: var(--s);
    background: white;
    background:
            linear-gradient(90deg,var(--c) 50%,#000 0) calc(100% - var(--_p,0%))/200% 100%,
            linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
    -webkit-background-clip: text,padding-box;

}

.menu-hover:hover {
    --_p: 100%;
}



